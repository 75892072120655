import { gql } from '~/__gql-generated__';
import { PostType, type InfoQuery } from '~/__gql-generated__/graphql';
import { type onStatelessParameters } from '@hocuspocus/provider';
import { cache, createAsync, useParams, type Params } from '@solidjs/router';
import { cookieStorage, makePersisted } from '@solid-primitives/storage';
import {
  batch,
  createEffect,
  createSignal,
  ErrorBoundary,
  For,
  mergeProps,
  onCleanup,
  onMount,
  Show,
  untrack,
} from 'solid-js';

import rootStyles from '~/components/Root.module.scss';
import styles from '~/components/Post.module.scss';
import { type Provider } from '~/components/post-editing/build-collab';
import SmartA from '~/components/SmartA';
import Title from '~/components/Title';
import NotFound from '~/routes/[...404]';
import { clientLazy } from '~/utils/clientLazy';
import { AUTH_TOKEN_COOKIE } from '~/utils/cookies';
import { fragmentToJsx } from '~/utils/fragmentToJsx';
import { client } from '~/utils/graphql';
import { renderFragment } from '~/utils/renderFragment';
import {
  generateRetinaThumbs,
  generateWidthThumbs,
} from '~/utils/generateThumbs';
import { toString } from 'hast-util-to-string';
import { visit } from 'unist-util-visit';
import {
  calculateReadingTime,
  wordSegmenter,
} from '~/utils/calculateReadingTime';

const Checkbox = clientLazy(() => import('~/components/post-editing/Checkbox'));
const DatePicker = clientLazy(
  () => import('~/components/post-editing/DatePicker'),
);
const Editor = clientLazy(() => import('~/components/post-editing/Editor'));
const Image = clientLazy(() => import('~/components/post-editing/Image'));
const Select = clientLazy(() => import('~/components/post-editing/Select'));
const Tags = clientLazy(() => import('~/components/post-editing/Tags'));

function toOptionRecord<T extends Record<string, any>>(
  array: T[],
  key: keyof T,
  value: keyof T,
) {
  const result: Record<string, string> = {};

  for (const item of array) {
    result[item[key]] = item[value]?.toString() ?? '';
  }

  return result;
}

const POST_LIGHT = gql(`
  query PostLight($id: ID!) {
    posts(where: { id: $id }) {
      author {
        id
      }
      category {
        id
      }
    }
  }
`);

const INFO = gql(`
  query Info($userId: ID!, $categoryId: ID) {
    users(
      where: {
        OR: [
          {
            OR: [
              { roles_INCLUDES: SUPER }
              { roles_INCLUDES: ADMIN }
              { roles_INCLUDES: AUTHOR_ADMIN }
              { roles_INCLUDES: AUTHOR }
            ]
            deletedAt: null
          }
          { id: $userId }
        ]
      }
      options: { sort: { displayName: ASC } }
    ) {
      id
      displayName
      profile {
        slug
        gender
        picture {
          url
        }
      }
    }
    categories(
      where: { OR: [{ deletedAt: null }, { id: $categoryId }] }
      options: { sort: { title: ASC } }
    ) {
      id
      title
      cover {
        url
      }
    }
  }
`);

const POST = gql(`
  query Post($slug: String!, $type: PostType!, $id: ID!) {
    posts(
      where: {
        OR: [{ slug: $slug, status: PUBLISHED, type: $type }, { id: $id }]
      }
    ) {
      id
      revisionsConnection(where: { edge: { status: CURRENT } }) {
        edges {
          node {
            title
            lead
            body
            publishedAt
            author {
              id
              displayName
              profile {
                slug
                gender
                picture {
                  url
                }
              }
            }
            category {
              id
              slug
              title
              cover {
                url
              }
            }
            cover {
              url
            }
            tags {
              id
              slug
              title
            }
          }
        }
      }
    }
  }
`);

export const getFullPost = cache(
  async (parameters: Params, type: PostType = PostType.Article) => {
    'use server';

    if (!parameters.slug && !parameters.id) {
      throw new Error('Post not found (empty slug)');
    }

    const result = await client.query({
      query: POST,
      variables: {
        slug: parameters.slug || '',
        id: parameters.id || '',
        type,
      },
    });
    if (result.data.posts.length === 0) {
      throw new Error('Post not found (id/slug mismatch)');
    }

    const post = result.data.posts[0];

    if (post.revisionsConnection.edges.length === 0 && parameters.id) {
      return {};
    }

    const revision = post.revisionsConnection.edges[0]?.node;

    const publishedAt = new Date(revision.publishedAt);

    if (!parameters.id && type === PostType.Article) {
      const year = Number.parseInt(parameters.year, 10);
      const month = Number.parseInt(parameters.month, 10);

      if (
        revision.category?.slug !== parameters.category ||
        publishedAt.getFullYear() !== year ||
        publishedAt.getMonth() + 1 !== month
      ) {
        throw new Error('Post not found (parameters mismatch)');
      }
    }

    const body = renderFragment(JSON.parse(revision.body ?? '{}'));

    const wordCount = [...wordSegmenter.segment(toString(body))].filter(
      segment => segment.isWordLike,
    ).length;

    let imageCount = 0;
    visit(body, 'element', node => {
      if (node.tagName === 'img') {
        imageCount++;
      }
    });

    return {
      title: renderFragment(JSON.parse(revision.title ?? '{}'), true),
      lead: renderFragment(JSON.parse(revision.lead ?? '{}'), true),
      body,
      publishedAt,
      author: revision.author,
      authorPictureSet: generateRetinaThumbs(
        revision.author.profile?.picture.url ?? '',
        '64x64',
      ),
      category: revision.category,
      cover: revision.cover,
      coverSet: generateWidthThumbs(revision.cover?.url ?? '', 16 / 9),
      tags: revision.tags,
      readingTime: calculateReadingTime(wordCount, imageCount),
    };
  },
  'post',
);

interface PostProps {
  readonly editMode?: boolean;
  readonly type?: PostType;
}

export default function Post(originalProps: PostProps) {
  const props = mergeProps({ type: PostType.Article }, originalProps);
  // eslint-disable-next-line solid/reactivity
  const [cookie] = makePersisted(createSignal(''), {
    name: AUTH_TOKEN_COOKIE,
    storage: cookieStorage,
    serialize: data => data,
    deserialize: data => data,
  });

  onMount(() => {
    if (props.editMode && !cookie()) {
      throw new Error('Post not found (unauthorized)');
    }
  });

  const [provider, setProvider] = createSignal<Provider | null>(null);
  const [userList, setUserList] = createSignal<Record<string, string>>({});
  const [extendedUserList, setExtendedUserList] = createSignal<
    InfoQuery['users']
  >([]);
  const [categoryList, setCategoryList] = createSignal<Record<string, string>>(
    {},
  );

  createEffect(() => {
    async function resolveProvider() {
      const {
        data: { posts },
      } = await client.query({
        query: POST_LIGHT,
        variables: { id: parameters.id },
      });

      if (posts.length === 0) {
        throw new Error('Post not found (unable to fetch)');
      }

      const [post] = posts;

      const {
        data: { users, categories },
      } = await client.query({
        query: INFO,
        variables: {
          userId: post.author.id,
          categoryId: post.category?.id,
        },
      });

      // TODO: !!!PROPERLY VALIDATE COOKIE SIGNATURE!!!
      const [, encodedCookie = '{}'] = cookie().split('.');
      const { sub } = JSON.parse(atob(encodedCookie));

      const thisUser = users.find(user => user.id === sub);
      const { buildProvider } = await import(
        '~/components/post-editing/build-collab'
      );

      batch(() => {
        setUserList(toOptionRecord(users, 'id', 'displayName'));
        setExtendedUserList(users);
        setCategoryList(toOptionRecord(categories, 'id', 'title'));
        setProvider(provider => {
          provider?.original.destroy();
          return buildProvider(parameters.id, {
            name: thisUser?.displayName,
            id: thisUser?.id,
            avatar: thisUser?.profile?.picture.url,
          });
        });
      });

      untrack(provider)
        ?.original.on('stateless', handleStateless)
        .on('connect', handleConnect)
        .on('disconnect', handleDisconnect)
        .on('synced', handleSynced);
    }

    if (!props.editMode || !parameters.id) {
      return;
    }

    if (cookie()) {
      void resolveProvider();
    }

    onCleanup(() => {
      untrack(provider)
        ?.original.off('stateless', handleStateless)
        .off('connect', handleConnect)
        .off('disconnect', handleDisconnect)
        .off('synced', handleSynced);
    });
  });

  const parameters = useParams();
  const post = createAsync(() => {
    if (!parameters.slug && !parameters.id) {
      return Promise.reject(new Error('Post not found (slug not provided)'));
    }

    return getFullPost({ ...parameters }, props.type);
  });

  let isConnected = false;
  const [isSaving, setIsSaving] = createSignal(true);
  const [saveMessage, setSaveMessage] = createSignal('');
  const [isSynced, setIsSynced] = createSignal(false);

  const handleStateless = ({ payload }: onStatelessParameters) => {
    if (!provider()) {
      return;
    }

    const json = JSON.parse(payload);

    switch (json.type) {
      case 'updatingPost':
      case 'sheddingRevision':
      case 'discardingRevision': {
        setIsSaving(true);
        break;
      }

      case 'postUpdated': {
        setSaveMessage('Alterações salvas automaticamente');
        setIsSaving(false);
        break;
      }

      case 'revisionShed': {
        setSaveMessage('Alterações salvas');
        setIsSaving(false);
        break;
      }

      case 'revisionDiscarded': {
        setSaveMessage('Modificações revertidas');
        setIsSaving(false);
        break;
      }

      case 'updateError':
      case 'shedError':
      case 'discardError': {
        setIsSaving(false);
        break;
      }

      default: // Do nothing
    }
  };

  const handleChange = () => {
    if (!provider() || !isSynced()) {
      return;
    }

    provider()?.original.sendStateless(JSON.stringify({ type: 'type' }));
  };

  const handleConnect = () => {
    if (!provider()) {
      return;
    }

    isConnected = true;
  };

  const handleDisconnect = () => {
    if (!provider()) {
      return;
    }

    isConnected = false;
    batch(() => {
      setIsSaving(true);
      setIsSynced(false);
    });
  };

  const handleSynced = () => {
    if (!provider() || !isConnected) {
      return;
    }

    batch(() => {
      setIsSaving(false);
      setIsSynced(true);
    });
  };

  const shedRevision = () => {
    provider()?.original.sendStateless(
      JSON.stringify({ type: 'shed', detail: {} }),
    );
  };

  const shedAndPublish = () => {
    provider()?.original.sendStateless(
      JSON.stringify({ type: 'shed', detail: { status: 'PUBLISHED' } }),
    );
  };

  const discardRevision = () => {
    provider()?.original.sendStateless(JSON.stringify({ type: 'discard' }));
  };

  const trashPost = () => {
    console.log(parameters);
    alert('Em breve...');
  };

  const [user, setUser] = createSignal('');
  const handleUserChange = (value: string) => {
    setUser(value);
    handleChange();
  };

  const [readingTime, setReadingTime] = createSignal(-1);
  const handleBodyChange = (value: any, readingTime: number) => {
    setReadingTime(readingTime);
    handleChange();
  };
  createEffect(() => {
    setReadingTime(post()?.readingTime ?? -1);
  });

  const readingTimeFormatted = () => {
    const minutes = readingTime();
    return `${minutes < 1 ? 'menos de ' : ''}${Math.max(1, minutes).toLocaleString(import.meta.env.VITE_LOCALE)} ${minutes >= 2 ? 'minutos' : 'minuto'}`;
  };

  return (
    <ErrorBoundary
      fallback={error => {
        console.error(error);
        return (
          <Show
            when={error.message.startsWith('Post not found')}
            fallback={
              <article class={rootStyles['styled-links']}>
                <h1>Ouch! Aconteceu um erro ao carregar a página</h1>
                <div class={styles.body}>
                  <p>
                    Mas não se preocupe, ele já está sendo analisado. Ou não. Se
                    ele persistir, mande uma mensagem no nosso{' '}
                    <i>
                      <a href="/chat">chat</a>
                    </i>{' '}
                    o quanto antes!
                  </p>
                </div>
              </article>
            }
          >
            <NotFound />
          </Show>
        );
      }}
    >
      <Title>
        {(props.editMode
          ? `Editando ${props.type === PostType.Article ? 'artigo' : 'página'}: `
          : '') + (fragmentToJsx(post()?.title, true) as string)}
      </Title>
      <main>
        <div>
          <Show when={provider() !== null}>
            {isSaving()
              ? isSynced()
                ? 'Salvando...'
                : 'Sincronizando...'
              : saveMessage()}
            &nbsp;
            <Show when={props.type === PostType.Article}>
              <p style={{ display: 'flex' }}>
                <Checkbox
                  collaborationProvider={provider()?.original}
                  collaborationField="sticky"
                  onChange={handleChange}
                />
                <label for="sticky">Fixar no topo</label>
              </p>
            </Show>
            <p>
              <button
                type="button"
                disabled={isSaving()}
                onClick={shedRevision}
              >
                Salvar Revisão
              </button>
              <button
                type="button"
                disabled={isSaving()}
                onClick={shedAndPublish}
              >
                Salvar e Publicar
              </button>
              <button
                type="button"
                disabled={isSaving()}
                onClick={discardRevision}
              >
                Descartar Revisão
              </button>
              <button type="button" disabled={isSaving()} onClick={trashPost}>
                Apagar Artigo
              </button>
            </p>
          </Show>
        </div>
        <article class={rootStyles['styled-links']}>
          <div class={styles.hero}>
            <Show
              when={provider() !== null}
              fallback={
                <img
                  class={styles['hero-image']}
                  src={post()?.cover?.url}
                  srcSet={post()?.coverSet}
                  sizes="auto"
                  alt=""
                />
              }
            >
              <label class={rootStyles['sr-only']} for="cover">
                Imagem de capa:
              </label>
              <Image
                class={styles['image-input']}
                collaborationProvider={provider()?.original}
                collaborationField="cover"
                onChange={handleChange}
              />
            </Show>
            <Show when={props.type === PostType.Article}>
              <div class={styles.category}>
                <Show
                  when={provider() !== null}
                  fallback={
                    <SmartA
                      class={rootStyles.pseudo}
                      href={`/${post()?.category?.slug ?? ''}`}
                    >
                      <span class={rootStyles['sr-only']}>Coluna: </span>
                      {post()?.category?.title}
                    </SmartA>
                  }
                >
                  <label class={rootStyles['sr-only']} for="category">
                    Coluna:
                  </label>
                  <Select
                    collaborationProvider={provider()?.original}
                    collaborationField="category"
                    options={categoryList()}
                    placeholder="Selecione coluna..."
                    onChange={handleChange}
                  />
                </Show>
              </div>
            </Show>
            <h1>
              <Show
                when={provider() !== null}
                fallback={fragmentToJsx(post()?.title)}
              >
                <Editor
                  inline
                  collaborationProvider={provider()}
                  collaborationField="title"
                  placeholder="Insira título..."
                  onChange={handleChange}
                />
              </Show>
            </h1>
            <p>
              <Show
                when={provider() !== null}
                fallback={fragmentToJsx(post()?.lead)}
              >
                <Editor
                  inline
                  collaborationProvider={provider()}
                  collaborationField="lead"
                  placeholder="Insira subtítulo..."
                  onChange={handleChange}
                />
              </Show>
            </p>
            <Show when={props.type === PostType.Article}>
              <div class={styles.meta}>
                <Show
                  when={provider() !== null}
                  fallback={
                    <>
                      <div class={styles['author-image']}>
                        <img
                          src={post()?.author?.profile?.picture.url}
                          srcSet={post()?.authorPictureSet}
                          alt=""
                        />
                      </div>
                      <div class={styles['extra-meta']}>
                        <div>
                          <SmartA
                            classList={{
                              [rootStyles.pseudo]: true,
                              [styles['author-link']]: true,
                            }}
                            href={`/${post()?.author?.profile?.slug ?? ''}`}
                          >
                            {post()?.author?.displayName}
                          </SmartA>{' '}
                          ·{' '}
                          {post()?.publishedAt?.toLocaleString(
                            import.meta.env.VITE_LOCALE,
                            {
                              dateStyle: 'long',
                              timeStyle: 'short',
                              timeZone: import.meta.env.VITE_TZ,
                            },
                          )}
                        </div>
                        <div title="Considerando 200 palavras por minuto e 12 segundos por imagem">
                          {readingTimeFormatted()} de leitura
                        </div>
                      </div>
                    </>
                  }
                >
                  <div class={styles['author-image']}>
                    <img
                      src={
                        extendedUserList().find(u => u.id === user())?.profile
                          ?.picture.url ?? 'about:blank'
                      }
                      alt=""
                      onLoad={event => {
                        event.currentTarget.classList.remove(styles.error);
                      }}
                      onError={event => {
                        event.currentTarget.classList.add(styles.error);
                      }}
                    />
                    <IconTablerUser />
                  </div>
                  <div class={styles['extra-meta']}>
                    <div class={styles.controls}>
                      <label class={rootStyles['sr-only']} for="author">
                        Autor(a):
                      </label>
                      <Select
                        collaborationProvider={provider()?.original}
                        collaborationField="author"
                        options={userList()}
                        placeholder="Selecione autor(a)..."
                        onChange={handleUserChange}
                        onRemoteChange={setUser}
                      />
                      {' · '}
                      <label class={rootStyles['sr-only']} for="publishedAt">
                        Data de publicação:
                      </label>
                      <DatePicker
                        collaborationProvider={provider()?.original}
                        collaborationField="publishedAt"
                        dateFormat={{
                          dateStyle: 'long',
                          timeStyle: 'short',
                          timeZone: import.meta.env.VITE_TZ,
                        }}
                        placeholder="Publicação imediata"
                        onChange={handleChange}
                      />
                    </div>
                    <div title="Considerando 200 palavras por minuto e 12 segundos por imagem">
                      {readingTimeFormatted()} de leitura
                    </div>
                  </div>
                </Show>
              </div>
            </Show>
          </div>
          <div class={styles.body}>
            <Show
              when={provider() !== null}
              fallback={fragmentToJsx(post()?.body)}
            >
              <Editor
                withLinks
                collaborationProvider={provider()}
                collaborationField="body"
                placeholder="Insira conteúdo..."
                onChange={handleBodyChange}
              />
            </Show>
          </div>
          <Show when={props.type === PostType.Article}>
            <Show
              when={provider() !== null}
              fallback={
                <Show when={(post()?.tags ?? []).length > 0}>
                  <div class={styles['tags-list']}>
                    <ul>
                      <For each={post()?.tags ?? []}>
                        {tag => (
                          <li>
                            <SmartA href={`/tag/${tag.slug}`}>
                              <span class={styles.hashtag}>#</span>
                              {tag.title}
                            </SmartA>
                          </li>
                        )}
                      </For>
                    </ul>
                  </div>
                </Show>
              }
            >
              <Tags
                collaborationProvider={provider()?.original}
                collaborationField="tags"
                onChange={handleChange}
              />
            </Show>
          </Show>
        </article>
      </main>
    </ErrorBoundary>
  );
}
